.App {
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.form {
  background: whitesmoke;
  padding: 7rem;
  width: 100vw;
}
.layout {
  margin: 15px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* border-bottom: 1px solid rgb(177, 177, 177); */
}

.flex {
  display: flex;
}
.extra {
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .form {
    border: none;
    padding: 0;
    width: 100%;
  }
  .layout {
    display: flex;
    flex-direction: column;
    border: none;
  }
  .flex {
    display: flex;
    flex-direction: column;
  }
  img {
    margin-top: 8px;
  }
  .extra {
    width: 100%;
  }
}
@media only screen and (min-width: 1300px) {
  .formfix {
    width: 75%;
  }
  .form {
    width: 100%;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
